import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#526F63",
      "fill-rule": "evenodd",
      d: "M16 4V3c0-.55.45-1 1-1s1 .45 1 1v1h1c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5a2 2 0 0 1-2-2l.01-14c0-1.1.88-2 1.99-2h1V3c0-.55.45-1 1-1s1 .45 1 1v1h8Zm-3 9h3c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1h-3c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1Zm6-5H5V6h14v2ZM6 20h12c.55 0 1-.41 1-.91V10H5v9.09c0 .5.45.91 1 .91Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }